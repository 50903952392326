<template>
    <svg height="24px" width="24px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve" fill="#000000">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
            <polygon style="fill:#ffffff;"
                points="256,22.23 317.195,191.236 501.801,200.815 363.092,304.447 407.913,489.77 256,371.251 104.087,489.77 149.929,304.447 10.2,200.815 195.825,191.236 ">
            </polygon>
            <g>
                <path style="fill:#050505;"
                    d="M104.085,499.969c-1.926,0-3.855-0.544-5.548-1.642c-3.648-2.366-5.395-6.785-4.351-11.007 l44.21-178.729L4.124,209.006c-3.457-2.564-4.932-7.021-3.688-11.141c1.244-4.119,4.94-7.015,9.238-7.237l178.826-9.228 l57.891-162.59c1.444-4.055,5.277-6.767,9.582-6.778c0.009,0,0.018,0,0.027,0c4.293,0,8.128,2.689,9.589,6.726l58.891,162.643 l177.848,9.228c4.29,0.223,7.981,3.111,9.23,7.221c1.249,4.111-0.213,8.563-3.654,11.135L374.586,308.59l43.24,178.784 c1.02,4.216-0.74,8.617-4.386,10.968s-8.381,2.14-11.801-0.53L256,384.187L110.361,497.812 C108.524,499.245,106.308,499.969,104.085,499.969z M256,361.052c2.215,0,4.431,0.719,6.274,2.158l128.849,100.525l-37.945-156.891 c-0.959-3.967,0.539-8.125,3.808-10.568l116.097-86.738l-156.418-8.117c-4.095-0.212-7.665-2.858-9.061-6.713L256.081,52.41 l-50.647,142.247c-1.382,3.883-4.967,6.552-9.082,6.764l-157.269,8.117l116.923,86.717c3.305,2.451,4.813,6.648,3.825,10.642 l-38.759,156.688l128.656-100.374C251.57,361.771,253.785,361.052,256,361.052z">
                </path>
                <path style="fill:#050505;"
                    d="M357.7,420.902c-4.602,0-8.775-3.134-9.905-7.804l-13.241-54.748 c-1.325-5.475,2.041-10.987,7.516-12.31c5.475-1.324,10.986,2.041,12.31,7.516l13.241,54.748c1.325,5.475-2.041,10.987-7.516,12.31 C359.301,420.808,358.493,420.902,357.7,420.902z">
                </path>
                <path style="fill:#050505;"
                    d="M337.059,335.557c-4.601,0-8.775-3.133-9.905-7.802l-0.494-2.041 c-1.325-5.475,2.039-10.987,7.514-12.312c5.477-1.327,10.987,2.04,12.312,7.514l0.494,2.041c1.325,5.475-2.039,10.987-7.514,12.312 C338.661,335.463,337.854,335.557,337.059,335.557z">
                </path>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: "StarIcon1",
};
</script>