<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="24" viewBox="0 0 120 50">
        <!-- Nền gradient với viền đậm -->
        <defs>
            <linearGradient id="bgGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" stop-color="#FFD700" />
                <stop offset="100%" stop-color="#FFA500" />
            </linearGradient>
        </defs>
        <rect width="120" height="50" rx="12" ry="12" fill="red" stroke="#D2691E" stroke-width="3" />

        <!-- Chữ NEW với bóng -->
        <text x="60" y="34" font-family="Arial, sans-serif" font-size="26" font-weight="bold" fill="#ffff"
            text-anchor="middle" stroke="black" stroke-width="1" paint-order="stroke fill">
            NEW
        </text>
    </svg>
</template>

<script>
export default {
    name: "NewIcon",
};
</script>