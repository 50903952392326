<template>
  <div class="favorite-coins mx-10">
    <div class="dropdown">
      <div class="dropdown-header flex items-center justify-between">
        <h1 class="dropdown-toggle flex items-center text-white">
          Mexc Coins
        </h1>
        <div class="header-controls flex items-center">
          <div class="select-form" @change="handleChange">
            <select v-model="periods" class="select-custom font-bold bg-white">
              <option value="">Select time</option>
              <option value="5m">5m</option>
              <option value="15m">15m</option>
              <option value="H1">1h</option>
              <option value="H4">4h</option>
              <option value="D1">1d</option>
            </select>
          </div>
          <button @click="handleRefresh"
            class="text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-2 focus:ring-gray-100 font-bold rounded-md text-sm px-5 py-2.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
            Refresh
          </button>
          <WhiteDownIcon/>
        </div>
      </div>
      <div class="dropdown-content">
        <div class="tabs font-bold">
          <button :class="['tab', { 'active': activeTab === 'favorite' }]" @click="activeTab = 'favorite'">
            Favorites
          </button>
          <button :class="['tab', { 'active': activeTab === 'all' }]" @click="activeTab = 'all'">
            All Coins
          </button>
        </div>
        <div class="search-container font-bold">
          <input type="text" v-model="searchQuery" @input="debouncedSearch" placeholder="Search by coin name..." />
        </div>
        <div class="coins-list-container">
          <div class="coins-list font-bold">
            <div v-for="coin in displayedCoins" :key="coin.id" class="coin-item">
              <span class="coin-name">{{ coin.coinName }}</span>
              <span class="new-icon">
                <NewIcon v-if="coin.isNew" />
              </span>
              <span class="star-icon" @click="toggleFavorite(coin)">
                <StarIcon1 v-if="coin.favoriteFlag === '0'" />
                <StarIcon2 v-else />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WhiteDownIcon from "@/assets/svg/WhiteDownIcon.vue";
import { API_URL } from "../config";
import debounce from "lodash/debounce";  // Import lodash debounce
import StarIcon1 from "@/assets/svg/StarIcon1.vue";
import StarIcon2 from "@/assets/svg/StarIcon2.vue";
import NewIcon from "@/assets/svg/NewIcon.vue";

export default {
  components: {
    WhiteDownIcon,
    StarIcon1,
    StarIcon2,
    NewIcon
  },
  data() {
    return {
      activeTab: 'favorite',
      periods: "",
      coins: [],  // Danh sách coin từ API
      searchQuery: "",  // Từ khóa tìm kiếm
      filteredCoins: [],  // Danh sách coin lọc theo từ khóa tìm kiếm
      loading: false,
    };
  },
  async created() {
    await this.fetchCoins();
  },
  computed: {
    displayedCoins() {
      if (this.activeTab === 'favorite') {
        return this.filteredCoins.filter(coin => coin.favoriteFlag === "1");
      } else {
        return this.filteredCoins;
      }
    },
  },
  methods: {
    async handleFetchCoins() {
      this.$emit("handle-fetchCoins");
    },
    async handleChange() {
      this.$emit("period-selected", this.periods);
    },
    async handleRefresh() {
      this.$emit("refresh-clicked", this.periods);
    },
    // Fetch danh sách coin từ API
    async fetchCoins() {
      try {
        const response = await fetch(`${API_URL}/futures/mexc/get-favorite`);
        const data = await response.json();
        this.coins = data.map(coin => ({
          ...coin,
          favoriteFlag: coin.favoriteFlag || "0",  // Đảm bảo favoriteFlag có giá trị mặc định
        }));
        this.filteredCoins = this.coins;  // Đặt giá trị ban đầu cho filteredCoins
      } catch (error) {
        console.error("Error fetching coins:", error);
      }
    },

    // Hàm tìm kiếm được debounce
    debouncedSearch: debounce(function () {
      // Thực hiện lọc theo từ khóa tìm kiếm
      this.filteredCoins = this.coins.filter(coin =>
        coin.coinName.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    }, 300),  // Trì hoãn 3 giây (3000ms) trước khi thực hiện tìm kiếm

    async toggleFavorite(coin) {
      if (this.loading) return; // Tránh nhấn nhiều lần
      this.loading = true;

      try {
        // Đảo trạng thái yêu thích
        const newFavoriteFlag = coin.favoriteFlag === "1" ? "0" : "1";

        // Cập nhật trạng thái trong local state
        coin.favoriteFlag = newFavoriteFlag;


        // Gọi API để cập nhật trạng thái yêu thích
        const requestBody = this.coins.map(coin => ({
          coinName: coin.coinName,
          favoriteFlag: coin.favoriteFlag,
        }));
        const response = await fetch(`${API_URL}/futures/mexc/update-favorite`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          throw new Error("Failed to update favorite status");
        }
        const data = await response.json();
        this.coins = data.map(coin => ({
          ...coin,
          favoriteFlag: coin.favoriteFlag || "0",
        }));
        this.filteredCoins = this.coins;
        await this.handleFetchCoins();
        // Hiển thị thông báo thành công
        this.$toast.open({
          message: `${coin.coinName} ${newFavoriteFlag === "1" ? "đã được thêm vào" : "đã xóa khỏi"} favorites!`,
          type: "success",
          position: "top-right",
        });
      } catch (error) {
        console.error("Error toggling favorite:", error);
        this.$toast.open({
          message: "Lỗi cập nhật trạng thái favorites!",
          type: "error",
          position: "top-right",
        });
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>

<style scoped></style>
