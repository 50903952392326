import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import ToastPlugin from 'vue-toast-notification';
import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import 'vue-loading-overlay/dist/css/index.css';
const app = createApp(App);

app.use(router); // sử dụng router
app.use(ToastPlugin);
app.use(LoadingPlugin);
app.mount('#app');