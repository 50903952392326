<template>
  <div>
    <div class="favorite-coins">
      <div class="dropdown">
        <div class="dropdown-header flex items-center justify-between">
          <h1 class="dropdown-toggle flex items-center text-white">
            Other
          </h1>
          <div class="header-controls flex items-center">
            <div class="select-form" @change="handleChange">
              <select v-model="periods" class="select-custom font-bold bg-white">
                <option value="">Select time</option>
                <option value="1m">1m</option>
                <option value="5m">5m</option>
                <option value="15m">15m</option>
                <option value="H1">1h</option>
                <option value="H4">4h</option>
                <option value="D1">1d</option>
              </select>
            </div>
            <button @click="handleRefresh"
              class="text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-2 focus:ring-gray-100 font-bold rounded-md text-sm px-5 py-2.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
              Refresh
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- OKX -->
    <div v-for="index in totalChart" :key="'chart-grid-' + index" class="chart-grid">
      <div class="chart-header text-center">
        <h3>
          OKX
        </h3>
      </div>
      <div class="chart-box">
        <div class="chart-balance-container five-chart">
          <canvas :id="fivechartOkxIds[index - 1]" :ref="'five-chart' + (index - 1)"></canvas>
        </div>
      </div>
      <div class="chart-box">
        <div class="chart-container first-chart">
          <canvas :id="chartOkxIds[index - 1]" :ref="'chart' + (index - 1)"></canvas>
        </div>
        <div class="chart-container second-chart">
          <canvas :id="secondchartOkxIds[index - 1]" :ref="'second-chart' + (index - 1)"></canvas>
        </div>
      </div>
      <div class="chart-box">
        <div class="chart-container third-chart">
          <canvas :id="thirdchartOkxIds[index - 1]" :ref="'third-chart' + (index - 1)"></canvas>
        </div>
        <div class="chart-container four-chart">
          <canvas :id="fourchartOkxIds[index - 1]" :ref="'four-chart' + (index - 1)"></canvas>
        </div>
      </div>
    </div>
    <!-- Bitget -->
    <div v-for="index in totalChart" :key="'chart-grid-' + index" class="chart-grid">
      <div class="chart-header text-center">
        <h3>
          Bitget
        </h3>
      </div>
      <div class="chart-box">
        <div class="chart-balance-container five-chart">
          <canvas :id="fivechartBitgetIds[index - 1]" :ref="'five-chart' + (index - 1)"></canvas>
        </div>
      </div>
      <div class="chart-box">
        <div class="chart-container first-chart">
          <canvas :id="chartBitgetIds[index - 1]" :ref="'chart' + (index - 1)"></canvas>
        </div>
        <div class="chart-container second-chart">
          <canvas :id="secondchartBitgetIds[index - 1]" :ref="'second-chart' + (index - 1)"></canvas>
        </div>
      </div>
      <div class="chart-box">
        <div class="chart-container third-chart">
          <canvas :id="thirdchartBitgetIds[index - 1]" :ref="'third-chart' + (index - 1)"></canvas>
        </div>
        <div class="chart-container four-chart">
          <canvas :id="fourchartBitgetIds[index - 1]" :ref="'four-chart' + (index - 1)"></canvas>
        </div>
      </div>
    </div>
    <!-- GateIO -->
    <div v-for="index in totalChart" :key="'chart-grid-' + index" class="chart-grid">
      <div class="chart-header text-center">
        <h3>
          Gate.io
        </h3>
      </div>
      <div class="chart-box">
        <div class="chart-balance-container five-chart">
          <canvas :id="fivechartGateIds[index - 1]" :ref="'five-chart' + (index - 1)"></canvas>
        </div>
      </div>
      <div class="chart-box">
        <div class="chart-container first-chart">
          <canvas :id="chartGateIds[index - 1]" :ref="'chart' + (index - 1)"></canvas>
        </div>
        <div class="chart-container second-chart">
          <canvas :id="secondchartGateIds[index - 1]" :ref="'second-chart' + (index - 1)"></canvas>
        </div>
      </div>
      <div class="chart-box">
        <div class="chart-container third-chart">
          <canvas :id="thirdchartGateIds[index - 1]" :ref="'third-chart' + (index - 1)"></canvas>
        </div>
        <div class="chart-container four-chart">
          <canvas :id="fourchartGateIds[index - 1]" :ref="'four-chart' + (index - 1)"></canvas>
        </div>
      </div>
    </div>
    <!-- MEXC -->
    <div v-for="index in totalChart" :key="'chart-grid-' + index" class="chart-grid">
      <div class="chart-header text-center">
        <h3>
          Mexc
        </h3>
      </div>
      <div class="chart-box">
        <div class="chart-balance-container five-chart">
          <canvas :id="fivechartMexcIds[index - 1]" :ref="'five-chart' + (index - 1)"></canvas>
        </div>
      </div>
      <div class="chart-box">
        <div class="chart-container first-chart">
          <canvas :id="chartMexcIds[index - 1]" :ref="'chart' + (index - 1)"></canvas>
        </div>
        <div class="chart-container second-chart">
          <canvas :id="secondchartMexcIds[index - 1]" :ref="'second-chart' + (index - 1)"></canvas>
        </div>
      </div>
      <div class="chart-box">
        <div class="chart-container third-chart">
          <canvas :id="thirdchartMexcIds[index - 1]" :ref="'third-chart' + (index - 1)"></canvas>
        </div>
        <div class="chart-container four-chart">
          <canvas :id="fourchartMexcIds[index - 1]" :ref="'four-chart' + (index - 1)"></canvas>
        </div>
      </div>
      <div class="chart-header text-center">
        <h3>
          Exchanges Total Balance
        </h3>
      </div>
      <div class="chart-box">
        <div class="chart-balance-container six-chart">
          <canvas :id="sixchartTotalIds[index - 1]" :ref="'six-chart' + (index - 1)"></canvas>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { API_URL } from "../config";
import {
  Chart as ChartJS,
  CategoryScale,
  TimeScale,
  BarController,
  BarElement,
  LinearScale,
  LineElement,
  PointElement,
  LineController,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import "chartjs-adapter-date-fns";
import "vue-select/dist/vue-select.css";

ChartJS.register(
  CategoryScale,
  TimeScale,
  BarController,
  BarElement,
  LinearScale,
  LineElement,
  PointElement,
  LineController,
  Title,
  Tooltip,
  Legend,
  Filler
);

export default {
  name: "OtherCoin",
  components: {
  },
  data() {
    return {
      selectedCoin: null,
      // OKX data
      chartOkxIds: Array.from({ length: 20 }, (_, i) => `okx-chart-${i}`),
      secondchartOkxIds: Array.from({ length: 20 }, (_, i) => `second-okx-chart-${i}`),
      thirdchartOkxIds: Array.from({ length: 20 }, (_, i) => `third-okx-chart-${i}`),
      fourchartOkxIds: Array.from({ length: 20 }, (_, i) => `four-okx-chart-${i}`),
      fivechartOkxIds: Array.from({ length: 200 }, (_, i) => `five-okx-chart-${i}`),
      chartsOkx: [],
      secondChartsOkx: [],
      thirdChartsOkx: [],
      fourChartsOkx: [],
      fiveChartsOkx: [],
      chartOkxDataSets: [],
      secondChartOkxDataSets: [],
      thirdChartOkxDataSets: [],
      fourChartOkxDataSets: [],
      fiveChartOkxDataSets: [],
      // Bitget data
      chartBitgetIds: Array.from({ length: 20 }, (_, i) => `bitget-chart-${i}`),
      secondchartBitgetIds: Array.from({ length: 20 }, (_, i) => `second-bitget-chart-${i}`),
      thirdchartBitgetIds: Array.from({ length: 20 }, (_, i) => `third-bitget-chart-${i}`),
      fourchartBitgetIds: Array.from({ length: 20 }, (_, i) => `four-bitget-chart-${i}`),
      fivechartBitgetIds: Array.from({ length: 200 }, (_, i) => `five-bitget-chart-${i}`),
      chartsBitget: [],
      secondChartsBitget: [],
      thirdChartsBitget: [],
      fourChartsBitget: [],
      fiveChartsBitget: [],
      chartBitgetDataSets: [],
      secondChartBitgetDataSets: [],
      thirdChartBitgetDataSets: [],
      fourChartBitgetDataSets: [],
      fiveChartBitgetDataSets: [],
      // GateIO data
      chartGateIds: Array.from({ length: 20 }, (_, i) => `gate-chart-${i}`),
      secondchartGateIds: Array.from({ length: 20 }, (_, i) => `second-gate-chart-${i}`),
      thirdchartGateIds: Array.from({ length: 20 }, (_, i) => `third-gate-chart-${i}`),
      fourchartGateIds: Array.from({ length: 20 }, (_, i) => `four-gate-chart-${i}`),
      fivechartGateIds: Array.from({ length: 200 }, (_, i) => `five-gate-chart-${i}`),
      chartsGate: [],
      secondChartsGate: [],
      thirdChartsGate: [],
      fourChartsGate: [],
      fiveChartsGate: [],
      chartGateDataSets: [],
      secondChartGateDataSets: [],
      thirdChartGateDataSets: [],
      fourChartGateDataSets: [],
      fiveChartGateDataSets: [],
      // total balance data
      sixChartTotal: [],
      sixChartTotalDataSets: [],
      sixchartTotalIds: Array.from({ length: 200 }, (_, i) => `six-total-chart-${i}`),
      // MEXC data
      chartMexcIds: Array.from({ length: 20 }, (_, i) => `mexc-chart-${i}`),
      secondchartMexcIds: Array.from({ length: 20 }, (_, i) => `second-mexc-chart-${i}`),
      thirdchartMexcIds: Array.from({ length: 20 }, (_, i) => `third-mexc-chart-${i}`),
      fourchartMexcIds: Array.from({ length: 20 }, (_, i) => `four-mexc-chart-${i}`),
      fivechartMexcIds: Array.from({ length: 200 }, (_, i) => `five-mexc-chart-${i}`),
      coinNames: [{ coinName: "PI" }],
      periods: "",
      chartsMexc: [],
      secondChartsMexc: [],
      thirdChartsMexc: [],
      fourChartsMexc: [],
      fiveChartsMexc: [],
      chartMexcDataSets: [],
      secondChartMexcDataSets: [],
      thirdChartMexcDataSets: [],
      fourChartMexcDataSets: [],
      fiveChartMexcDataSets: [],
      loading: false,
      coins: [],
      totalChart: 0,
      arrayCoin: [],
      displayedCoins: [],
      typeCoin: 'marker',
      requestData: {
        mexc: {
          market: "MEXC",
          typeOne: "PLATFORM_ELITE_TOTAL_AMOUNT",
          typeTwo: "PLATFORM_TOTAL_USERS",
          typeThree: "PLATFORM_ELITE_TOTAL_USERS",
          urlOI: `${API_URL}/mexc/openinterest`,
          urlFuture: `${API_URL}/futures/position`,
          urlBalance: `${API_URL}/other/get-exchanges-balance`,
          titleOne: "Elite Account Long-Short Position Holding Ratio",
          titleTwo: "Platform's Long-Short Position Holders Ratio",
          titleThree: "Elite Account Long-Short Position Holders Ratio",
        },
        okx: {
          market: "OKX",
          typeOne: "eliteLSAccountRatio",
          typeTwo: "eliteLSOIRatio",
          typeThree: "LSAccountRatio",
          urlOI: `${API_URL}/okx/openinterest`,
          urlFuture: `${API_URL}/okx/longshort-ratio`,
          urlBalance: `${API_URL}/other/get-exchanges-balance`,
          titleOne: "Elite Account Long-Short Position Holding Ratio",
          titleTwo: "Platform's Long-Short Position Holders Ratio",
          titleThree: "Elite Account Long-Short Position Holders Ratio",
        },
        bitget: {
          market: "BITGET",
          typeOne: "1",
          typeTwo: "2",
          typeThree: "3",
          urlOI: `${API_URL}/bitget/openinterest`,
          urlFuture: `${API_URL}/bitget/longshort-ratio`,
          urlBalance: `${API_URL}/other/get-exchanges-balance`,
          titleOne: "Top Traders Account Long/Short",
          titleTwo: "Top Traders Position Long/Short",
          titleThree: "Account Long/Short",
        },
        gate: {
          market: "GATEIO",
          typeOne: "1",
          typeTwo: "2",
          typeThree: "3",
          urlOI: `${API_URL}/gate/openinterest`,
          urlFuture: `${API_URL}/gate/longshort-ratio`,
          urlBalance: `${API_URL}/other/get-exchanges-balance`,
          titleOne: "Top Traders Account Long/Short",
          titleTwo: "Top Traders Position Long/Short",
          titleThree: "Account Long/Short",
        },
        totalBalance: {
          market: "TOTAL",
          urlBalance: `${API_URL}/other/get-exchanges-balance`,
        }
      }
    };
  },
  async created() {
    // await this.fetchCoins();
  },
  methods: {
    async handleChange() {
      await this.handleSearch()
    },
    async handleRefresh() {
      await this.handleSearch()
    },

    async handleSearch() {
      if (this.periods == "") {
        this.$toast.open({
          message: 'Vui lòng chọn thời gian!',
          type: 'error',
          position: 'top-right',
        });
        return false
      }
      const loading = this.$loading.show();
      const filteredArray = this.coinNames
        .filter((item) => item != null && item !== "")
        .map((item) => (typeof item === "string" ? item : item.coinName));
      this.coinNames = filteredArray;
      const promises = filteredArray.map((coinName, i) => {
        return Promise.all([
          this.fetchNotionalData(coinName, this.periods, this.requestData.okx),
          this.fetchNotionalData(coinName, this.periods, this.requestData.bitget),
          this.fetchNotionalData(coinName, this.periods, this.requestData.gate),
          this.fetchNotionalData(coinName, this.periods, this.requestData.mexc),
          this.fetchBalanceData(coinName, this.periods, this.requestData.okx),
          this.fetchBalanceData(coinName, this.periods, this.requestData.bitget),
          this.fetchBalanceData(coinName, this.periods, this.requestData.gate),
          this.fetchBalanceData(coinName, this.periods, this.requestData.mexc),
          this.fetchBalanceData(coinName, this.periods, this.requestData.totalBalance),
          this.fetchApiDataForCoin(coinName, i, this.periods, this.requestData.okx, this.requestData.okx.typeOne),
          this.fetchApiDataForCoin(coinName, i, this.periods, this.requestData.okx, this.requestData.okx.typeTwo),
          this.fetchApiDataForCoin(coinName, i, this.periods, this.requestData.okx, this.requestData.okx.typeThree),
          this.fetchApiDataForCoin(coinName, i, this.periods, this.requestData.bitget, this.requestData.bitget.typeOne),
          this.fetchApiDataForCoin(coinName, i, this.periods, this.requestData.bitget, this.requestData.bitget.typeTwo),
          this.fetchApiDataForCoin(coinName, i, this.periods, this.requestData.bitget, this.requestData.bitget.typeThree),
          this.fetchApiDataForCoin(coinName, i, this.periods, this.requestData.gate, this.requestData.gate.typeOne),
          this.fetchApiDataForCoin(coinName, i, this.periods, this.requestData.gate, this.requestData.gate.typeTwo),
          this.fetchApiDataForCoin(coinName, i, this.periods, this.requestData.gate, this.requestData.gate.typeThree),
          this.fetchApiDataForCoin(coinName, i, this.periods, this.requestData.mexc, this.requestData.mexc.typeOne),
          this.fetchApiDataForCoin(coinName, i, this.periods, this.requestData.mexc, this.requestData.mexc.typeTwo),
          this.fetchApiDataForCoin(coinName, i, this.periods, this.requestData.mexc, this.requestData.mexc.typeThree),
        ]).then(([okxNotional, bitgetNotional, gateNotional, mexcNotional, okxBalanceData, bitgetBalanceData, gateBalanceData, mexcBalanceData, totalBalanceData]) => {
          if (okxNotional.market === this.requestData.okx.market) {
            this.chartOkxDataSets[i] = okxNotional.result;
          }
          if (bitgetNotional.market === this.requestData.bitget.market) {
            this.chartBitgetDataSets[i] = bitgetNotional.result;
          }
          if (gateNotional.market === this.requestData.gate.market) {
            this.chartGateDataSets[i] = gateNotional.result;
          }
          if (mexcNotional.market === this.requestData.mexc.market) {
            this.chartMexcDataSets[i] = mexcNotional.result;
          }
          if (okxBalanceData.market === this.requestData.okx.market) {
            this.fiveChartOkxDataSets[i] = okxBalanceData.result;
          }
          if (bitgetBalanceData.market === this.requestData.bitget.market) {
            this.fiveChartBitgetDataSets[i] = bitgetBalanceData.result;
          }
          if (gateBalanceData.market === this.requestData.gate.market) {
            this.fiveChartGateDataSets[i] = gateBalanceData.result;
          }
          if (mexcBalanceData.market === this.requestData.mexc.market) {
            this.fiveChartMexcDataSets[i] = mexcBalanceData.result;
          }
          if (totalBalanceData.market === this.requestData.totalBalance.market) {
            this.sixChartTotalDataSets[i] = totalBalanceData.result;
          }

        });
      });

      await Promise.all(promises);
      this.totalChart = filteredArray.length;
      this.arrayCoin = this.coinNames;
      this.loading = false;
      // create chart okx
      this.createChartsOpenInterest(this.fivechartOkxIds, this.fiveChartsOkx, this.fiveChartOkxDataSets)
      this.createChartsOpenInterest(this.chartOkxIds, this.chartsOkx, this.chartOkxDataSets)
      this.createChartsFuture(this.secondchartOkxIds, this.secondChartsOkx, this.secondChartOkxDataSets, this.requestData.okx.titleOne, this.requestData.okx.market);
      this.createChartsFuture(this.thirdchartOkxIds, this.thirdChartsOkx, this.thirdChartOkxDataSets, this.requestData.okx.titleTwo, this.requestData.okx.market);
      this.createChartsFuture(this.fourchartOkxIds, this.fourChartsOkx, this.fourChartOkxDataSets, this.requestData.okx.titleThree, this.requestData.okx.market);
      // create chart Bitget
      this.createChartsOpenInterest(this.fivechartBitgetIds, this.fiveChartsBitget, this.fiveChartBitgetDataSets)
      this.createChartsOpenInterest(this.chartBitgetIds, this.chartsBitget, this.chartBitgetDataSets)
      this.createChartsFuture(this.secondchartBitgetIds, this.secondChartsBitget, this.secondChartBitgetDataSets, this.requestData.bitget.titleOne, this.requestData.bitget.market);
      this.createChartsFuture(this.thirdchartBitgetIds, this.thirdChartsBitget, this.thirdChartBitgetDataSets, this.requestData.bitget.titleTwo, this.requestData.bitget.market);
      this.createChartsFuture(this.fourchartBitgetIds, this.fourChartsBitget, this.fourChartBitgetDataSets, this.requestData.bitget.titleThree, this.requestData.bitget.market);
      // create chart gateio
      this.createChartsOpenInterest(this.fivechartGateIds, this.fiveChartsGate, this.fiveChartGateDataSets)
      this.createChartsOpenInterest(this.chartGateIds, this.chartsGate, this.chartGateDataSets)
      this.createChartsFuture(this.secondchartGateIds, this.secondChartsGate, this.secondChartGateDataSets, this.requestData.gate.titleOne, this.requestData.gate.market);
      this.createChartsFuture(this.thirdchartGateIds, this.thirdChartsGate, this.thirdChartGateDataSets, this.requestData.gate.titleTwo, this.requestData.gate.market);
      this.createChartsFuture(this.fourchartGateIds, this.fourChartsGate, this.fourChartGateDataSets, this.requestData.gate.titleThree, this.requestData.gate.market);
      // create chart mexc
      this.createChartsOpenInterest(this.fivechartMexcIds, this.fiveChartsMexc, this.fiveChartMexcDataSets)
      this.createChartsOpenInterest(this.chartMexcIds, this.chartsMexc, this.chartMexcDataSets)
      this.createChartsFuture(this.secondchartMexcIds, this.secondChartsMexc, this.secondChartMexcDataSets, this.requestData.mexc.titleOne, this.requestData.mexc.market);
      this.createChartsFuture(this.thirdchartMexcIds, this.thirdChartsMexc, this.thirdChartMexcDataSets, this.requestData.mexc.titleTwo, this.requestData.mexc.market);
      this.createChartsFuture(this.fourchartMexcIds, this.fourChartsMexc, this.fourChartMexcDataSets, this.requestData.mexc.titleThree, this.requestData.mexc.market);
      // create total balance
      this.createChartsOpenInterest(this.sixchartTotalIds, this.sixChartTotal, this.sixChartTotalDataSets)
      loading.hide();
    },

    async fetchNotionalData(coinName, period, requestData) {
      let periodData = "1d";
      if (period === 'H1') {
        periodData = '1h';
      } else if (period === 'H2') {
        periodData = '2h';
      } else if (period === 'H4') {
        periodData = '4h';
      } else if (period === '5m') {
        periodData = '5m';
      } else if (period === '15m') {
        periodData = '15m';
      } else if (period === '1m') {
        periodData = '5m';
      } else {
        periodData = '1d';
      }
      if (requestData.market === "MEXC" || requestData.market === "GATEIO") {
        coinName = coinName + "_USDT"
      }
      if (requestData.market === "BITGET") {
        coinName = coinName + "USDT"
      }
      try {
        const requestBody = {
          symbol: coinName,
          period: periodData,
        };
        let response = {};
        response = await fetch(requestData.urlOI, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody),
        });
        const data = await response.json();
        const last20Indexes = Math.max(0, data.length - 20)
        const convertData = data.slice(last20Indexes).map((item) => {
          const [datePart, timePart] = item.time.split(" ");
          const [year, month, day] = datePart.split("-").map(Number);
          const [hour, minute] = timePart.split(":").map(Number);
          const timestamp = new Date(year, month - 1, day, hour, minute).getTime();
          const openInterestValue = parseFloat(item.openInterest.replace(/,/g, ""));
          return { x: timestamp, y: openInterestValue }
        });
        return { result: convertData.sort((a, b) => a.x - b.x), market: requestData.market };

      } catch (error) {
        console.error(
          `Lỗi khi tải dữ liệu cho ${coinName}:`,
          error.message || error
        );
        return { result: [], market: requestData.market };
      }
    },
    async fetchBalanceData(coinName, period, requestData) {
      let periodData = "1d";
      if (period === 'H1') {
        periodData = '1h';
      } else if (period === 'H2') {
        periodData = '2h';
      } else if (period === 'H4') {
        periodData = '4h';
      } else if (period === '5m') {
        periodData = '5m';
      } else if (period === '15m') {
        periodData = '15m';
      } else if (period === '1m') {
        periodData = '1m';
      } else {
        periodData = '1d';
      }
      try {
        const requestBody = {
          symbol: coinName,
          period: periodData,
          market: requestData.market
        };
        let response = {};
        response = await fetch(`${API_URL}/other/get-exchanges-balance`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody),
        });
        const data = await response.json();
        const last200Indexes = Math.max(0, data.length - 200)
        const convertData = data.slice(last200Indexes).map((item) => {
          const [datePart, timePart] = item.time.split(" ");
          const [year, month, day] = datePart.split("-").map(Number);
          const [hour, minute] = timePart.split(":").map(Number);
          const timestamp = new Date(year, month - 1, day, hour, minute).getTime();
          const openInterestValue = parseFloat(item.balance.replace(/,/g, ""));
          return { x: timestamp, y: openInterestValue }
        });
        return { result: convertData.sort((a, b) => a.x - b.x), market: requestData.market };
      } catch (error) {
        console.error(
          `Lỗi khi tải dữ liệu cho ${coinName}:`,
          error.message || error
        );
        return { result: [], market: requestData.market };
      }
    },
    async fetchApiDataForCoin(coinName, index, period, requestData, type) {
      const requestBody = {
        symbol: coinName,
        type: type,
      };
      if (requestData.market === "OKX" || requestData.market === "GATEIO") {
        if (period === 'H1') {
          period = '1H';
        } else if (period === 'H2') {
          period = '2H';
        } else if (period === 'H4') {
          period = '4H';
        } else if (period === 'D1') {
          period = '1D';
        } else if (period === '1m') {
          period = '1H';
        }
        requestBody.period = period
      }
      if (requestData.market === "GATEIO") {
        requestBody.symbol = coinName + "_USDT"
      }
      if (requestData.market === "BITGET") {
        coinName = coinName + "USDT"
      }
      if (requestData.market === "MEXC") {
        if (period === '1m') {
          period = 'H1';
          requestBody.period = period
        }
        requestBody.symbol = coinName + "_USDT"
        requestBody.currency = 'USDT'
        requestBody.interval = period
      }

      try {

        const response = await fetch(
          requestData.urlFuture,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(requestBody),
          }
        );
        const dataResponse = await response.json();

        if (!dataResponse.success) {
          throw new Error(`HTTP error! Status: ${response.code}`);
        }
        const data = dataResponse.data;
        const result = data.time.map((time, index) => {
          const longPercent = data.longRatio[index] * 100;
          const shortPercent = data.shortRatio[index] * 100;

          return {
            x: time,
            y: parseFloat(data.longShortRatio[index].toFixed(2)),
            longPercent: parseFloat(longPercent.toFixed(2)),
            shortPercent: parseFloat(shortPercent.toFixed(2)),
            longShortRatio: parseFloat(data.longShortRatio[index].toFixed(2)),
          };
        });
        if (type === this.requestData.mexc.typeOne) {
          this.secondChartMexcDataSets[index] = result;
        } else if (type === this.requestData.mexc.typeTwo) {
          this.thirdChartMexcDataSets[index] = result;
        } else if (type === this.requestData.mexc.typeThree) {
          this.fourChartMexcDataSets[index] = result;
        } else if (type === this.requestData.okx.typeOne) {
          this.secondChartOkxDataSets[index] = result;
        } else if (type === this.requestData.okx.typeTwo) {
          this.thirdChartOkxDataSets[index] = result;
        } else if (type === this.requestData.okx.typeThree) {
          this.fourChartOkxDataSets[index] = result;
        } else if (type === this.requestData.gate.typeOne) {
          this.secondChartGateDataSets[index] = result;
        } else if (type === this.requestData.gate.typeTwo) {
          this.thirdChartGateDataSets[index] = result;
        } else if (type === this.requestData.gate.typeThree) {
          this.fourChartGateDataSets[index] = result;
        } else if (type === this.requestData.bitget.typeOne) {
          this.secondChartBitgetDataSets[index] = result;
        } else if (type === this.requestData.bitget.typeTwo) {
          this.thirdChartBitgetDataSets[index] = result;
        } else if (type === this.requestData.bitget.typeThree) {
          this.fourChartBitgetDataSets[index] = result;
        }
      } catch (error) {
        console.error(
          `Lỗi khi tải dữ liệu từ API mới cho ${coinName}:`,
          error.message || error
        );
      }
    },
    createChartsOpenInterest(chartIdsData, chartsData, chartSetsData) {
      this.$nextTick(() => {
        let periods = this.periods;
        chartIdsData.slice(0, this.totalChart).forEach((chartId, index) => {
          const canvas = document.getElementById(chartId);
          if (canvas) {
            const ctx = canvas.getContext("2d");
            if (chartsData[index]) {
              chartsData[index].destroy();
            }

            const dataSet = chartSetsData[index] || [];
            chartsData[index] = new ChartJS(ctx, {
              type: "line",
              data: {
                datasets: [
                  {
                    label: `Giá trị bề mặt của các khoản lệnh đang mở`,
                    data: dataSet,
                    borderColor: "#3498db",
                    backgroundColor: "rgba(52, 152, 219, 0.5)",
                    fill: true,
                    tension: 0,
                    borderWidth: 2,
                  },
                ],
              },
              options: {
                plugins: {
                  legend: {
                    display: true,
                  },
                  datalabels: {
                    display: false,
                  },
                  title: {
                    display: true,
                    text: dataSet.length === 200
                      ? `Exchanges Balance (${this.coinNames[index]})`
                      : `Open Interest (${this.coinNames[index]})`,
                  },
                  tooltip: {
                    enabled: true,
                  },
                },
                interaction: {
                  mode: "index",
                  intersect: false,
                },
                scales: {
                  x: {
                    type: "time",
                    time: {
                      ...(this.periods === "D1" || this.periods === "1D" && {
                        round: "day",
                      }),
                      unit:
                        this.periods === "H1" || this.periods === "H4" || this.periods === "1H" || this.periods === "4H"
                          ? "hour"
                          : this.periods === "D1" || this.periods === "1D"
                            ? "day"
                            : "minute",
                    },
                    labels: dataSet.map((item) => item.x),
                    ticks: {
                      maxTicksLimit: dataSet.length,
                      ...(this.periods !== "D1" && this.periods !== "1D" && {
                        stepSize:
                          this.periods === "D1" || this.periods === "1D"
                            ? 1
                            : this.periods === "H4" || this.periods === "4H"
                              ? 4
                              : this.periods === "5m" || (dataSet.length !== 200 && this.periods === "1m")
                                ? 5
                                : this.periods === "15m"
                                  ? 15
                                  : 1,
                      }),
                      callback: function (value) {
                        const date = new Date(value);
                        if (periods === "D1") {
                          return date.toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                          });
                        } else {
                          return date.toLocaleTimeString("en-GB", {
                            hour: "2-digit",
                            minute: "2-digit",
                          });
                        }
                      },
                    },
                    stacked: true,
                  },
                },
                responsive: true,
                maintainAspectRatio: false,
              },
            });
          }
        });
      })
    },
    createChartsFuture(chartIdsData, chartData, chartSetData, chartName, market) {
      this.$nextTick(() => {
        let periods = this.periods;
        chartIdsData
          .slice(0, this.totalChart)
          .forEach((chartId, index) => {
            const canvas = document.getElementById(chartId);
            if (canvas) {
              const ctx = canvas.getContext("2d");
              if (chartData[index]) {
                chartData[index].destroy();
              }

              const dataSet = chartSetData[index] || [];
              const minValue = Math.min(
                ...dataSet.map((item) => item.longShortRatio)
              );
              const roundedValue = Math.floor(minValue / 0.05) * 0.05;
              const startValue =
                minValue - roundedValue <= 0.05
                  ? roundedValue - 0.5
                  : roundedValue + 0.5;

              chartData[index] = new ChartJS(ctx, {
                type: "bar",
                data: {
                  datasets: [
                    {
                      label: "Long holding / Short holding",
                      type: "line",
                      data: dataSet,
                      borderColor: "rgba(0, 0, 0, 1)",
                      pointBackgroundColor: "rgba(255, 255, 255, 1)",
                      pointBorderColor: "rgba(0, 0, 0, 1)",
                      pointBorderWidth: 1.5,
                      pointRadius: 4,
                      pointHoverRadius: 6,
                      fill: true,
                      tension: 0.4,
                      borderWidth: 2,
                      yAxisID: "y-line",
                      meta: {
                        customValue: dataSet,
                      },
                    },
                    {
                      label: "% Long holding ratio",
                      data: dataSet.map((item) => item.longPercent),
                      backgroundColor: "rgba(0, 209, 112, 0.8)",
                      stack: "Stack 0",
                      yAxisID: "y-bar",
                      meta: {
                        customValue: dataSet,
                      },
                    },
                    {
                      label: "% Short holding ratio",
                      data: dataSet.map((item) => item.shortPercent),
                      backgroundColor: "rgba(231, 76, 60, 0.8)",
                      stack: "Stack 0",
                      yAxisID: "y-bar",
                      meta: {
                        customValue: dataSet,
                      },
                    },
                  ],
                },
                options: {
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: true,
                    },
                    datalabels: {
                      display: false
                    },
                    title: {
                      display: true,
                      text: `${chartName} (${this.coinNames[index]})`,
                    },
                    tooltip: {
                      callbacks: {
                        label: function (tooltipItem) {
                          const dataset = tooltipItem.dataset;
                          const rawData = dataset.meta.customValue;
                          const index = tooltipItem.dataIndex;
                          const shortPercent = rawData[index].shortPercent || 0;
                          const longPercent = rawData[index].longPercent || 0;
                          const longShortRatio =
                            rawData[index].longShortRatio || 0;
                          return `Long: ${longPercent}%\r\nShort: ${shortPercent}%\r\nLong Short Ratio: ${longShortRatio}`;
                        },
                      },
                    },
                  },
                  scales: {
                    x: {
                      type: "time",
                      time: {
                        ...(periods === "D1" || periods === "1D" && {
                          round: "day",
                        }),
                        unit:
                          (periods === "H1"
                            || periods === "H4" || periods === "H2" || periods === "1m" || (market === "MEXC" && (periods === "5m"
                              || periods === "15m"))) || (periods === "1H"
                                || periods === "4H" || periods === "2H")
                            ? "hour"
                            : periods === "D1" || periods === "1D"
                              ? "day"
                              : "minute",
                      },
                      labels: dataSet.map((item) => item.x),
                      ticks: {
                        maxTicksLimit: 30,
                        ...(periods !== "D1" && periods !== "1D" && {
                          stepSize:
                            (periods === "H1") || (market === "MEXC" && (periods === "5m"
                              || periods === "15m"))
                              || periods === "1H"
                              ? 1
                              : periods === "H4" || periods === "4H"
                                ? 4
                                : ((market === "OKX" || market === "GATEIO") && periods === "15m")
                                  ? 15
                                  : ((market === "OKX" || market === "GATEIO") && periods === "5m")
                                    ? 5
                                    : 1,
                        }),
                        callback: function (value) {
                          const date = new Date(value);
                          if (periods === "D1" || periods === "1D") {
                            return date.toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                            });
                          } else {
                            return date.toLocaleTimeString("en-GB", {
                              hour: "2-digit",
                              minute: "2-digit",
                            });
                          }
                        },
                      },
                      stacked: true,
                    },
                    "y-line": {
                      id: "y-line",
                      position: "right",
                      grid: {
                        drawOnChartArea: false,
                      },
                      beginAtZero: false,
                      min: startValue,
                      ticks: {
                        callback: function (value) {
                          return value.toFixed(2);
                        },
                      },
                    },
                    y: {
                      display: false,
                      grid: {
                        display: false,
                      },
                    },
                  },
                },
              });
            }
          });
      });
    },
  },
};
</script>

<style scoped>
.form-group-horizontal {
  display: flex;
  align-items: center;
  gap: 10px;
}

.input-form {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 10px;
  margin-bottom: 20px;
}

.input-form:hover,
.input-group {
  margin-bottom: 10px;
}

.input-group input {
  width: 80%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
}


.chart-grid {
  margin: 0 auto;
  width: 100%;
}

.chart-grid .chart-box {
  border: 2px solid #ccc;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.chart-container {
  flex: 1 1 calc(33.33% - 20px);
  max-width: 50%;
  min-width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chart-balance-container {
  flex: 1 1 calc(33.33% - 20px);
  max-width: 100%;
  min-width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.first-chart,
.second-chart,
.third-chart,
.four-chart,
.five-chart,
.six-chart {
  width: 100%;
}

.chart-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.chart-header h3 {
  font-size: 20px;
  margin: 0;
  color: #333;
}
</style>
