<template>
  <div id="app">
    <!-- Các tab -->
    <div class="tabs">
      <button @click="selectedTab = 'overview'" :class="{ active: selectedTab === 'overview', 'tab-overview': true }">
        Overview
      </button>
      <button @click="selectedTab = 'binance'" :class="{ active: selectedTab === 'binance', 'tab-binance': true }">
        Binance
      </button>
      <button @click="selectedTab = 'mexc'" :class="{ active: selectedTab === 'mexc', 'tab-mexc': true }">
        Mexc
      </button>
      <button @click="selectedTab = 'bybit'" :class="{ active: selectedTab === 'bybit', 'tab-bybit': true }">
        Bybit
      </button>
      <button @click="selectedTab = 'okx'" :class="{ active: selectedTab === 'okx', 'tab-okx': true }">
        OKX
      </button>
      <button @click="selectedTab = 'bitget'" :class="{ active: selectedTab === 'bitget', 'tab-bitget': true }">
        Bitget
      </button>
      <button @click="selectedTab = 'gateio'" :class="{ active: selectedTab === 'gateio', 'tab-gateio': true }">
        Gate.io
      </button>
      <button @click="selectedTab = 'kucoin'" :class="{ active: selectedTab === 'kucoin', 'tab-kucoin': true }">
        Kucoin
      </button>
      <button @click="selectedTab = 'other'" :class="{ active: selectedTab === 'other', 'tab-other': true }">
        Other
      </button>
    </div>

    <div v-if="selectedTab === 'overview'">
      <Overview />
    </div>
    <div v-if="selectedTab === 'binance'">
      <Binance />
    </div>
    <div v-if="selectedTab === 'mexc'">
      <Mexc />
    </div>
    <div v-if="selectedTab === 'bybit'">
      <Bybit />
    </div>
    <div v-if="selectedTab === 'okx'">
      <OKX />
    </div>
    <div v-if="selectedTab === 'bitget'">
      <Bitget />
    </div>
    <div v-if="selectedTab === 'gateio'">
      <GateIO />
    </div>
    <div v-if="selectedTab === 'kucoin'">
      <Kucoin />
    </div>
    <div v-if="selectedTab === 'other'">
      <Other />
    </div>
  </div>
</template>

<script>
import { API_URL } from './config';
import Overview from './components/Overview.vue';
import Binance from './components/Binance.vue';
import Mexc from './components/Mexc.vue';
import Bybit from './components/Bybit.vue';
import OKX from './components/OKX.vue';
import Bitget from './components/Bitget.vue';
import GateIO from './components/GateIO.vue';
import Kucoin from './components/Kucoin.vue';
import Other from './components/Other.vue';
import './assets/css/index.css';
export default {
  components: {
    Overview,
    Binance,
    Mexc,
    Bybit,
    OKX,
    Bitget,
    GateIO,
    Kucoin,
    Other
  },
  data() {
    return {
      selectedTab: 'overview',
      coinName: '',
      minMarketcap: null,
      maxMarketcap: null,
      sortOrder: 'asc',
      coins: [],
      currentPage: 1,
      itemsPerPage: 10,
      loading: false,
      error: null
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.coins.length / this.itemsPerPage);
    },
    paginatedCoins() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.coins.slice(start, end);
    }
  },
  methods: {
    async fetchMarketcap() {
      this.loading = true;
      this.error = null;

      const payload = {
        coinName: this.coinName,
        minMarketcap: this.minMarketcap ? this.minMarketcap.toString() : "",
        maxMarketcap: this.maxMarketcap ? this.maxMarketcap.toString() : "",
        sortOrder: this.sortOrder
      };

      try {
        const response = await fetch(`${API_URL}/tool/getmarketcap`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        });

        if (!response.ok) {
          throw new Error('Failed to fetch data. Please try again later.');
        }

        const data = await response.json();
        this.coins = data || [];
      } catch (error) {
        this.error = error.message;
        this.coins = [];
      } finally {
        this.loading = false;
      }
      this.currentPage = 1;
    },

    async updateMarketcap() {
      this.loading = true;
      this.error = null;

      try {
        const response = await fetch(`${API_URL}/tool/update-marketcap`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
        });

        if (!response.ok) {
          throw new Error('Failed to update marketcap. Please try again later.');
        }

        alert('Marketcap updated successfully!');
        
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },

    editMaxSupply(coin) {
      coin.isEditing = true;
      coin.newMaxSupply = coin.maxSupply;
    },

    async saveMaxSupply(coin) {
      try {
        const confirmed = confirm("Are you sure you want to save the updated Max Supply?");
        if (!confirmed) {
          return;
        }

        const payload = {
          coinName: coin.coinName,
          maxSupply: coin.newMaxSupply
        };
        const response = await fetch(`${API_URL}/tool/update`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        });

        if (!response.ok) {
          throw new Error('Failed to update max supply.');
        }

        coin.maxSupply = coin.newMaxSupply;
        coin.isEditing = false;
      } catch (error) {
        this.error = error.message;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage += 1;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
      }
    },
    clearFilters() {
      this.coinName = '';
      this.minMarketcap = null;
      this.maxMarketcap = null;
      this.sortOrder = 'asc';
      this.coins = [];
      this.error = null;
      this.currentPage = 1;
    }
  },
};
</script>

<style scoped>
.tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  padding: 10px;
  background: #c1a6e6;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  max-width: 1200px;
  flex-wrap: wrap;
  gap: 8px;
}

.tabs button {
  padding: 12px 24px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: #cccdef;
  color: #020010;
  position: relative;
  overflow: hidden;
  letter-spacing: 0.3px;
}

.tabs button:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  transform: translateY(-1px);
}

.tabs button.active {
  color: white;
  font-weight: 600;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

/* Overview */
.tab-overview.active {
  background: linear-gradient(135deg, #60a5fa 0%, #3b82f6 100%);
}

/* Binance */
.tab-binance.active {
  background: linear-gradient(135deg, #fbbf24 0%, #f59e0b 100%);
}

/* Mexc */
.tab-mexc.active {
  background: linear-gradient(135deg, #34d399 0%, #059669 100%);
}

/* Bybit */
.tab-bybit.active {
  background: linear-gradient(135deg, #a855f7 0%, #7c3aed 100%);
}

/* OKX */
.tab-okx.active {
  background: linear-gradient(135deg, #2563eb 0%, #1d4ed8 100%);
}

/* Bitget */
.tab-bitget.active {
  background: linear-gradient(135deg, #fb923c 0%, #ea580c 100%);
}

/* Gate.io */
.tab-gateio.active {
  background: linear-gradient(135deg, #f87171 0%, #dc2626 100%);
}

/* Kucoin */
.tab-kucoin.active {
  background: linear-gradient(135deg, #2dd4bf 0%, #0d9488 100%);
}

/* Other */
.tab-other.active {
  background: linear-gradient(135deg, #94a3b8 0%, #64748b 100%);
}

/* Hover effects for active tabs */
.tabs button.active:hover {
  opacity: 0.9;
  transform: translateY(-1px);
}

.tabs button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.1);
}

/* Ripple effect */
.tabs button::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, rgba(255, 255, 255, 0.2) 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform .5s, opacity 1s;
}

.tabs button:active::after {
  transform: scale(0, 0);
  opacity: .5;
  transition: 0s;
}

/* Responsive design */
@media (max-width: 768px) {
  .tabs {
    padding: 8px;
    gap: 6px;
  }

  .tabs button {
    padding: 10px 16px;
    font-size: 13px;
  }
}

.highlight-red {
  background-color: #ffcccc;
  color: #ff0000;
  font-weight: bold;
}

.pagination select {
  margin-right: 10px;
  padding: 8px;
  font-size: 14px;
}

.pagination {
  display: flex;
  margin-top: 20px;
}
.pagination button {
  padding: 8px 16px;
  margin: 0 5px;
  font-size: 14px;
  cursor: pointer;
}
.pagination span {
  font-size: 16px;
  line-height: 32px;
}
.loading {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

.btn-action {
  padding: 0px 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.sort-order, .limit-select {
  padding: 10px;
  width: 170px;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.container {
  display: flex;
  gap: 20px;
  margin: 0 auto;
  max-width: 1200px;
}

.filters, .results {
  flex: 1;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.filters {
  max-width: 400px;
}

.filter-block {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.input-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
}

label {
  font-weight: bold;
}

input, select {
  padding: 8px;
  font-size: 16px;
  width: 150px;
  border: 2px solid #ccc; /* Viền mặc định */
  border-radius: 5px;
  transition: all 0.3s ease; /* Thêm hiệu ứng khi thay đổi */
}

input:focus, select:focus {
  outline: none; /* Loại bỏ viền mặc định khi focus */
  border-color: #3498db; /* Viền xanh biển khi focus */
  box-shadow: 0 0 5px rgba(52, 152, 219, 0.5); /* Hiệu ứng bóng đổ khi focus */
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

</style>

