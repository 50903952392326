import { createRouter, createWebHistory } from 'vue-router';
import LineChart from './components/Binance.vue';
import FavoriteCoin from './components/FavoriteCoin.vue';
import LineChartMexc from './components/Mexc.vue';

const routes = [
  {
    path: '/line-chart',
    name: 'LineChart',
    component: LineChart
  },
  {
    path: '/line-chart-mexc',
    name: 'LineChartMexc',
    component: LineChartMexc
  },
  {
    path: '/favorite',
    name: 'favoriteCoin',
    component: FavoriteCoin
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
